import type { IntlShape } from 'react-intl';
import config from '@packages/config';
import type { CaptchaData } from '@packages/modules/src/Captcha/types';
import type { FormSubmitResult } from '../../types';
import { generalFormMessages } from '../../messages';
import type { FormDevice } from '../GeneralForm.types';

export type PostDataCustomerData = {
  firstName: string;
  lastName: string;
  customerId: string;
  street: string;
  housenumber: string;
  city: string;
  postalCode: string;
  emailAddress: string;
  phoneNumber: string;
  salutation?: string;
  dateOfBirth?: string;
};

/**
 * Post Data that can be sent with a contact form
 */
export type PostDataMessage = {
  subject: string;
  message: string;
  customerData: PostDataCustomerData;
  cancelNL?: boolean;
  cancelAdvertising?: boolean;
  cancelMarketing?: boolean;
  messageType: 'CONTACT' | 'RSV' | 'REVOCATION';
};

/**
 * Post Data that creates your email newsletter subscription
 */
export type PostDataSubscribe = {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  emailAddress: string;
  areaKey: string;
};

/**
 * Post Data that changes your email newsletter subscription
 */
export type PostDataChange = {
  type: string;
  typeValue: number;
  emailAddress: string;
  areaKey: string;
};

export type PostDataType = PostDataMessage | PostDataChange | PostDataSubscribe;

interface ErrorType {
  statusCode: number;
  statusMessage: string;
}

/**
 * This function posts the form's state postData to the configured url
 */
export const handleSubmit = async (
  onSuccess: (result: FormSubmitResult) => void,
  onError: (errormsg: string) => void,
  intl: IntlShape,
  postData: PostDataType,
  endpoint: 'message' | 'change' | 'subscribe',
  deviceType: 'web' | 'mob' | 'app',
  captchaFn: (captchaData: CaptchaData) => void,
) => {
  const fetchUrl = `${config.forms.apiAgnitasUrl.values.url}/${endpoint}`;

  // areaKey from query param that has priority over given areaKey in postData
  const areaKey = new URLSearchParams(window.location.search).get('areaKey');

  const finalPostData = {
    contextUri: window.location.pathname === '/' ? '/storefront' : window.location.pathname,
    deviceType,
    locale: intl.locale.substring(0, 2),
    ...postData,
    ...(areaKey && { areaKey }),
  };
  // todo - refactor later to use await rather than "then" and "catch" for more readability
  await fetch(fetchUrl, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(finalPostData),
  })
    .then(
      // step 1: validate response
      (response: Response) => {
        const hasError = !response.ok && !response.redirected;
        // do not handle status 503 as error because status 503 returns captcha json which should used for rendering the captcha component
        if (hasError && response.status !== 503) {
          const error: ErrorType = {
            statusCode: response.status,
            statusMessage: response.statusText,
          };
          throw new Error(JSON.stringify(error));
        }
        // Read the response as json.
        return response.json();
      },
    )
    .then(
      // step 2: read json and return result
      (formSubmitResult): JSON => {
        if (formSubmitResult.fieldName && formSubmitResult.imageUrl && formSubmitResult.actionUrl) {
          captchaFn(formSubmitResult);
        } else {
          onSuccess(formSubmitResult);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
        return formSubmitResult;
      },
    )
    .catch((error: Error) => {
      const errorObject: ErrorType = JSON.parse(error.message);

      // in case of error fire notification
      onError(
        errorObject.statusCode === 404
          ? intl.formatMessage(generalFormMessages.errorApiRecipientNotFound)
          : intl.formatMessage(generalFormMessages.errorApi),
      );
    });
};
export type ConvertedFormMessageDataType = {
  subject: string;
  message: string;
  customerData: {
    firstName: string;
    lastName: string;
    customerId: string;
    street: string;
    housenumber: string;
    city: string;
    postalCode: string;
    emailAddress: string;
    phoneNumber?: string;
    salutation?: string;
    dateOfBirth?: string;
  };
  messageType: 'RSV' | 'CONTACT' | 'REVOCATION';
};
export type ConvertedFormNLDataType = {
  emailAddress: string;
  firstName?: string;
  lastName?: string;
  salutation?: string;
  areaKey: string;
};
export type ConvertedFormUnsubNLDataType = {
  emailAddress: string;
  areaKey: string;
  typeValue: number;
  type: string;
};
export const submitMessage = async <TErrorType extends string>({
  formatMessage,
  language,
  formDeviceType: deviceType,
  convertedFormMessageData,
  convertedFormNLData,
  convertedFormUnsubNLData,
  setError,
  endpoint,
  onSuccess,
  onError,
}: {
  formatMessage: IntlShape['formatMessage'];
  language: string;
  formDeviceType: FormDevice;
  convertedFormMessageData?: ConvertedFormMessageDataType;
  convertedFormUnsubNLData?: ConvertedFormUnsubNLDataType;
  convertedFormNLData?: ConvertedFormNLDataType;
  setError: (name: TErrorType, error: { message: string }) => void;
  onSuccess?: (resultType: string) => void;
  onError?: (errorMsg: string) => void;
  endpoint: 'message' | 'change' | 'subscribe';
}) => {
  const fetchUrl = `${config.forms.apiAgnitasUrl.values.url}/${endpoint}`;
  // const fetchUrl = `/api/mockAgnitas/message`;

  // areaKey from query param that has priority over given areaKey in postData
  const areaKey = new URLSearchParams(window.location.search).get('areaKey');
  const finalPostData = {
    contextUri: window.location.pathname === '/' ? '/storefront' : window.location.pathname,
    deviceType,
    locale: language,
    ...(convertedFormMessageData || convertedFormNLData || convertedFormUnsubNLData),
    ...(areaKey && { areaKey }),
  };
  let response: Response;
  try {
    response = await fetch(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(finalPostData),
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    setError('root.message' as TErrorType, {
      message: formatMessage(generalFormMessages.errorApi),
    });
    onError?.(formatMessage(generalFormMessages.errorApi));
    return;
  }
  if (!response.ok) {
    const message =
      response.status === 404
        ? formatMessage(generalFormMessages.errorApiRecipientNotFound)
        : formatMessage(generalFormMessages.errorApi);
    setError('root.message' as TErrorType, { message });
    onError?.(message);
  } else {
    const result = await response.json();
    onSuccess?.(result.resultType);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};
