import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import type { FormDevice } from '../GeneralForm.types';

export const useFormDeviceType = (): FormDevice => {
  const { isTablet, isMobile } = useDeviceType();

  const { getCookies } = useCookies();
  const cookies = getCookies();
  const isApp = cookies.appView === 'true';

  // eslint-disable-next-line no-nested-ternary
  return isApp ? 'app' : isMobile ? 'mob' : isTablet ? 'tab' : 'web';
};
